<template>
    <div>
        <div id="page-wrapper" class="m-0 w-100">
            <div class="row">
                <b-navbar toggleable="md" variant="primary" type="dark" class="w-100">
<!--                        <b-navbar-brand :to="homepage.route" class="p-0">-->
<!--                            <b-img v-if="homepage.logo !== ''" :src="homepage.logo" style="max-height: 56px;" alt="Logo"></b-img>-->
<!--                            <template v-else>{{homepage.title}}</template>-->
<!--                        </b-navbar-brand>-->

                    <b-button v-b-toggle.sidebar><font-awesome-icon icon="bars"></font-awesome-icon></b-button>

                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
<!--                                <b-button variant="outline-light">-->
<!--                                    <font-awesome-icon icon="bell"></font-awesome-icon>-->
<!--                                </b-button>-->

                        <b-nav-item v-b-modal="'vehicle-activity-modal'" @click="getAvailability" :active="true"
                                    v-b-tooltip.hover.bottom title="Disponibilidad de vehículos">
                            <font-awesome-icon icon="car"></font-awesome-icon>
                            <span class="mr-2 d-none d-md-inline"></span>
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'Profile' }" :active="true" v-b-tooltip.hover.bottom title="Mi perfil">
                            <font-awesome-icon icon="user-circle"></font-awesome-icon> {{user.name}}
                            <span class="mr-2 d-none d-md-inline"></span>
                        </b-nav-item>

<!--                                <b-nav-item :href="DOCS" target="_blank" :active="true" v-b-tooltip.hover.bottom title="Ayuda">-->
<!--                                    <span class="ml-2 d-none d-md-inline"></span>-->
<!--                                    <font-awesome-icon icon="question-circle"></font-awesome-icon> <span class="d-inline d-md-none">Ayuda</span>-->
<!--                                </b-nav-item>-->

                        <b-nav-item @click="logOut" :active="true" v-b-tooltip.hover.bottom title="Cerrar sesión">
                            <span class="ml-2 d-none d-md-inline"></span>
                            <font-awesome-icon icon="sign-out-alt"></font-awesome-icon> <span class="d-inline d-md-none">Cerrar sesión</span>
                        </b-nav-item>
                    </b-navbar-nav>
                </b-navbar>
            </div>
            <div class="row wrapper border-bottom foreground page-heading">
                <Breadcrumb :title="breadcrumb.title" :path="breadcrumb.path"></Breadcrumb>
            </div>
            <div class="container-fluid wrapper wrapper-content">
                <router-view @update-breadcrumb="updateBreadcrumb" @user-data-change="updateUserData"></router-view>
            </div>
        </div>

        <Sidebar :menu="menu"></Sidebar>

        <b-modal id="vehicle-activity-modal" scrollable centered size="xl">
            <template #modal-header>
                <div>
                    <h3>Disponibilidad de los vehiculos</h3>
                </div>
            </template>
            <template>
                <form>
                    <b-form-group label="Fecha" label-cols-md="2">
                        <b-form-datepicker boundary="window" placeholder="Fecha" v-bind="datePickerLabels"
                                           locale="es" hide-header v-model="filters.date"></b-form-datepicker>
                    </b-form-group>
                </form>
                <hr>
                <b-table-simple responsive bordered>
                    <b-tbody>
                        <template v-for="(availableVehicle, index) in availableVehicles">
                            <b-tr :key="'available-vehicle-' + index" style="background-color: #F5F5F5">
                                <b-td colspan="3">
                                    <b-row>
                                        <b-col md="9" class="m-0 font-weight-bold">
                                            {{ availableVehicle.brand + ' ' + availableVehicle.model + ' [' + availableVehicle.licensePlate + ']' }} <span class="text-primary" v-if="availableVehicle.type == 'RENT'">(R)</span> <span class="text-primary" v-if="availableVehicle.type == 'EXTERNAL_SERVICE'">(EXS)</span>
                                        </b-col>
                                        <b-col md="3">
                                            <h5 class="mr-2 mt-1 mb-0 font-weight-bold text-right">{{ availableVehicle.passengerCapacity }}</h5>
                                        </b-col>
                                    </b-row>
                                    <template v-if="availableVehicle.serviceOrderId">
                                        <label class="mt-0 mb-0">{{ availableVehicle.serviceName }}</label>
                                    </template>
                                    <label class="text-primary mb-0" v-if="availableVehicle.serviceOrders.length === 0">Sin asignación</label>
                                </b-td>
                            </b-tr>
                            <b-tr v-for="(serviceOrder) in availableVehicle.serviceOrders"
                                  :key="'available-vehicle-service-order-' + serviceOrder.id" style="background-color: #FFFFFF">
                                <b-td style="width: 4%; border-left: none; text-align: center;"></b-td>
                                <b-td style="width: 12%; text-align: center;">
                                    <b-row>
                                        <b-col cols="12" class="m-0" v-if="serviceOrder.status === 'DRAFT'">
                                            <h4><b-badge variant="warning" class="button-sticky">Borrador</b-badge></h4>
                                        </b-col>
                                        <b-col cols="12" class="m-0" v-if="serviceOrder.status === 'OPEN'">
                                            <h4><b-badge variant="info" class="button-sticky">Abierta</b-badge></h4>
                                        </b-col>
                                        <b-col cols="12" class="m-0" v-if="serviceOrder.status === 'FINISHED'">
                                            <h4><b-badge variant="primary" class="button-sticky">Terminada</b-badge></h4>
                                        </b-col>
                                        <b-col cols="12" class="m-0" v-if="serviceOrder.status === 'CANCELLED'">
                                            <h4><b-badge variant="danger" class="button-sticky">Cancelada</b-badge></h4>
                                        </b-col>
                                    </b-row>
                                </b-td>
                                <b-td>
                                    <b-row>
                                        <b-col md="5" class="m-0">
                                            <router-link :to="{ name: 'ServiceOrderShow', params: { id: serviceOrder.id }}" target="_blank">{{ serviceOrder.folio }}</router-link> | {{ serviceOrder.serviceName }}
                                        </b-col>
                                        <b-col md="4" class="m-0">
                                            <template v-if="serviceOrder.serviceDurationType == 'SPECIFIC'">
                                                <label class="mt-0 mb-0 text-primary" v-if="serviceOrder.startTime">{{ serviceOrder.startTime.split(':').slice(0,2).join(':') }} - {{ serviceOrder.endTime.split(':').slice(0,2).join(':') }}</label>
                                                <label class="mt-0 mb-0 text-danger" v-if="!serviceOrder.startTime || !serviceOrder.endTime">Horario no asignado</label>
                                            </template>
                                            <label class="mt-0 mb-0 text-primary" v-if="serviceOrder.serviceDurationType == 'ALL'">Todo el dia</label>
                                        </b-col>
                                        <b-col md="3">
                                            <h5 class="mr-2 mt-1 mb-0 font-weight-bold text-right"><span :class="{'text-primary': (availableVehicle.passengerCapacity - (serviceOrder.adultsQty + serviceOrder.childrenQty)) > 0, 'text-success': (availableVehicle.passengerCapacity - (serviceOrder.adultsQty + serviceOrder.childrenQty)) === 0 }">{{ serviceOrder.adultsQty + serviceOrder.childrenQty }} / {{ availableVehicle.passengerCapacity }}</span></h5>
                                        </b-col>
                                    </b-row>
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </template>
            <template #modal-footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" @click="$bvModal.hide('vehicle-activity-modal')" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { logOutUrl, vehicleAvailabilityUrl } from '@routes';
import * as constants from '@constants';
import Breadcrumb from '@cooper/src/components/bootstrap/Breadcrumb.vue';
import Sidebar from '../components/sidebar';
import { datePickerLabels } from '../i18n/datePickerI18n';
const dayjs = require('dayjs');

export default {
    data() {
        return {
            homepage: {
                logo: '',
                title: 'Inicio',
                route: { name: 'UserIndex' }
            },
            user: {
                id: 0,
                name: '',
                email: '',
                roles: ''
            },
            availableVehicles: [],
            filters: {
                date: ''
            },
            datePickerLabels: datePickerLabels.es,
            breadcrumb: {
                title: '',
                path: []
            },
            menu: [
                {
                    title: 'Reservaciones',
                    icon: '',
                    module: 'businessOrder',
                    route: { name: 'BusinessOrderIndex' }
                },
                {
                    title: 'Operaciones',
                    icon: '',
                    module: 'operations',
                    route: { name: 'ServiceOrderNew' }
                },
                {
                    title: 'Ordenes de Servicio',
                    icon: '',
                    module: 'serviceOrder',
                    route: { name: 'ServiceOrderIndex' }
                },
                {
                    title: 'Cuentas',
                    icon: '',
                    module: 'accountRp',
                    route: { name: 'AccountRpIndex' }
                },
                {
                    title: 'Pagos',
                    icon: '',
                    module: 'payment',
                    route: { name: 'PaymentIndex' }
                },
                {
                    title: 'Gastos',
                    icon: '',
                    module: 'expense',
                    route: { name: 'ExpenseIndex' }
                },
                {
                    title: 'Reportes',
                    icon: '',
                    module: 'report',
                    route: { name: 'ReportIndex' }
                },
                {
                    title: 'Catálogos',
                    header: true,
                    children: [
                        {
                            title: 'Hoteles',
                            icon: 'hotel',
                            module: 'hotel',
                            route: { name: 'HotelIndex' }
                        },
                        {
                            title: 'Servicios',
                            icon: 'map-marked-alt',
                            module: 'service',
                            route: { name: 'ServiceIndex' }
                        },
                        {
                            title: 'Clientes',
                            icon: 'user',
                            module: 'customer',
                            route: { name: 'CustomerIndex' }
                        },
                        {
                            title: 'Proveedores',
                            icon: 'user',
                            module: 'supplier',
                            route: { name: 'SupplierIndex' }
                        }
                    ]
                },
                {
                    title: 'Configuración',
                    header: true,
                    children: [
                        {
                            title: 'Usuarios',
                            icon: 'users',
                            module: 'user',
                            route: { name: 'UserIndex' }
                        },
                        {
                            title: 'Sucursales',
                            icon: 'store-alt',
                            module: 'branch',
                            route: { name: 'BranchIndex' }
                        },
                        {
                            title: 'Vehículos',
                            icon: 'car',
                            module: 'vehicle',
                            route: { name: 'VehicleIndex' }
                        }
                    ]
                }
            ]
        };
    },
    created() {
        this.filters.date = dayjs().format('YYYY-MM-DD');
    },
    components: {
        Breadcrumb,
        Sidebar
    },
    methods: {
        updateUserData(user) {
            this.user.id = user.id;
            this.user.name = user.name;
        },
        logOut() {
            document.body.classList.remove('margin-left-sidebar');
            document.body.classList.add('no-margin-sidebar');

            this.axios.delete(logOutUrl(), {
                data: { clientToken: localStorage.getItem('clientToken') }
            }).finally(() => {
                localStorage.removeItem('userInfo');
                localStorage.removeItem('userSettings');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('clientId');
                localStorage.removeItem('clientSecret');
                localStorage.removeItem('clientToken');

                this.user = {
                    id: 0,
                    name: '',
                    email: '',
                    roles: ''
                };

                this.$router.replace({ name: 'Login' });
            });
        },
        updateBreadcrumb(breadcrumb) {
            this.breadcrumb = breadcrumb;
        },
        capitalize(value) {
            if (!value) {
                return '';
            }

            value.toString();

            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        getAvailability() {
            this.axios.get(vehicleAvailabilityUrl(), {
                params: this.filters
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.availableVehicles = response.data.data;
                    this.serviceOrders = this.availableVehicles.map(availableVehicle => availableVehicle.serviceOrders).flat();
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    watch: {
        'filters.date'() {
            this.getAvailability();
        }
    },
    computed: {
        setActiveUserMenu() {
            return this.$route.name.includes('User');
        },
        DOCS() {
            return constants.BACK_OFFICE_DOCS;
        }
    }
};
</script>

<style>
</style>